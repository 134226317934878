import React from 'react';
import './footer.css';
import logoBlack from '../images/logo-black.png';
import bscscan50x50 from '../images/bscscan50x50.png';
// import line40x40 from '../images/line40x40.png';
import twitterCopy from '../images/twitter-Copy.png';
import telegramfw from '../images/telegram.fw.png';
// import youtube1fw from '../images/youtube-1.fw.png';
import githubfw from '../images/github.fw.png';
import { Link } from 'react-router-dom';

export default function Footer() {

    return (
        <React.Fragment>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-12">
                            <img src={logoBlack} className="logo" alt="" />
                            <p>Earn passive income on your investments up to 7.25% crypto rewards and fixed rates.get weekly notifications of your earnings</p>
                        </div>
                        <div className="col-md-3 col-12">
                            <h2 class="foo-title">Main Menu</h2>
                            <ul className='foo-list'>
                                <li>

                                    <Link to="/">
                                        Home
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/saving">
                                        Saving
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/my-saving">
                                        My-Saving
                                    </Link>
                                </li>
                                <li>
                                    <a href="https://mms-soilminerals.com/" target="_balnk" rel="noreferrer">
                                        Website
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" rel="noreferrer" href="https://vindax.com/exchange-advanced.html?symbol=MMSC_USDT">
                                        BUY TOKENS
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3 col-12">
                            <h2 class="foo-title">Use Links</h2>
                            <ul className='foo-list'>
                                <li>
                                    <a target="_blank" rel="noreferrer" href="https://coinmarketcap.com/currencies/mmsc-platform/?utm_medium=widget&amp;utm_campaign=cmcwidget&amp;utm_source=mms-soilminerals.com&amp;utm_content=mmsc%20platform">
                                        Coinmarketcap
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" rel="noreferrer" href="https://www.coingecko.com/en/coins/mms-coin">
                                        Coingecko
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" rel="noreferrer" href="https://www.livecoinwatch.com/price/MMSCoin-MMSC">
                                        Livecoinwatch
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" rel="noreferrer" href="https://nomics.com/assets/mmsc-mms-coin">
                                        Nomics
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3 col-12 widget">
                            <h2 class="foo-title">Contact us</h2>
                            <div className="social-icon gap-3">
                                <a target="_blank" rel="noreferrer" href="https://bscscan.com/token/0x67Db74b6D1Ea807CB47248489c99D144323D348d" className="caption-color">
                                    <img src={bscscan50x50} height={40} width={40} alt="bsc" />
                                </a>
                                <a target="_blank" rel="noreferrer" href="https://t.me/+seMX1nx2Bgk4ZTFl" className="caption-color">
                                    <img src={telegramfw} height={40} width={40} alt="bsc" />
                                </a>
                                <a target="_blank" rel="noreferrer" href="https://twitter.com/mmsplatform/" className="caption-color">
                                    <img src={twitterCopy} height={40} width={40} alt="bsc" />
                                </a>
                                <a target="_blank" rel="noreferrer" href="https://github.com/Goldcopytrade/MMSCOIN/" className="caption-color">
                                    <img src={githubfw} height={40} width={40} alt="bsc" />
                                </a>
                            </div>
                        </div>


                    </div>
                </div>
            </footer>
            <div className='copy-rights-section'>
                <div className='container'>
                    <div className='row'>
                        <div className="col-md-12 d-flex justify-content-center text-white">
                            <div>Copyright © 2023 MMS COIN All Right Reserve</div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
