import React, { useState } from 'react'
import { CopyToClipboard } from "react-copy-to-clipboard";
import bannerImg from '../images/banner.png';
import sideImg from '../images/side-img.png';
import work from '../images/work.jpg';
import copyImg from '../images/copy.png';
import { Link } from 'react-router-dom';


export default function Home() {
    const [addrcopy, setAddrcopy] = useState(false);

    return (
        <>
            <div id="hero-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            <div className="mb-4">
                                <h2>MMS Coin Best Crypto Currency Platforms for Staking</h2>
                            </div>
                            <p>
                                Earn passive income on your investments up to 7.25% crypto rewards and fixed rates.get weekly notifications of your earnings. Make passive income. let your money work for you every day.
                            </p>
                            <div className="d-flex gap-2 gap-sm-4 mb-5 mb-md-0">
                                <a href="https://vindax.com/exchange-base.html?symbol=MCASH_USDT" target="_blank" rel="noreferrer" className="cs-btn cs-btn2">
                                    Buy Token &nbsp;&nbsp;<i className="fa-solid fa-arrow-right ml-2"></i>
                                </a>
                                <Link to="/saving" className="cs-btn">
                                    Saving Now&nbsp;&nbsp;<i className="fa-solid fa-arrow-right ml-2"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-5 d-flex justify-content-end">
                            <div className="hero-img">
                                {/* <YouTube videoId="6p7NJl0Ozu4" />
                                {/* <video width="320" height="240" controls>
                                    <source src="https://www.youtube.com/watch?v=6p7NJl0Ozu4" type="video/mp4" />
                                </video> */}
                                <img src={bannerImg} className="lazy img-fluid" alt="pattern" style={{ "height": "350px", "width": "350px" }} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div id="token-section" className="py-4 py-sm-5">
                <div className="container">
                    <div className='token-box'>
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                                <div className=''>
                                    <div className="fs-28 mb-4">
                                        MMS COIN (MMSC) Token Contract BSC
                                    </div>
                                    <div className="token-copy d-flex align-items-center overflow-hidden">
                                        <input id="token" type="text" className="form-control border-0 px-4" value="0x67Db74b6D1Ea807CB47248489c99D144323D348d" />
                                        <CopyToClipboard text="0x67Db74b6D1Ea807CB47248489c99D144323D348d" onCopy={() => {
                                            setAddrcopy(true);
                                            setTimeout(() => {
                                                setAddrcopy(false);
                                            }, 2000)
                                        }}>
                                            <div type="button"
                                                className="align-items-center bg-primary d-flex flex-shrink-0 fs-2 h-100 img justify-content-center text-white">
                                                <img className="img-fluid scale" src={copyImg} alt="" />
                                                {addrcopy && <small className="text-center">copied</small>}
                                            </div>

                                        </CopyToClipboard>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="about-section" className='py-4 py-sm-5 mt-5'>
                <div className="container">
                    <div className='row'>
                        <div className='col-sm-12 col-md-12 col-lg-6'>
                            <h1 className='head-title text-center'>About MMS Coin Saving</h1>
                            <div className='mgt-header-line'></div>
                            <div className='sub-title'>We are bringing to you a revolutionary Staking Platform that yields real-world rewards, luxury prizes and exclusive lifestyle perks.</div>
                            <p>MMSC Coin is a Marketplace platform we create created an online store, membership services, besides being used in exchanges on trading boards, MMS Coin can be used to buy products through the Platform. Connect Wallet to the store. press payment and wait to receive the product from the company</p>
                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-6 d-flex justify-content-end'>
                            <img src={sideImg} className="lazy img-fluid" alt="pattern" style={{"height" : "400px" , "width" : "400px"}} />
                        </div>
                    </div>
                </div>
            </div>
            <div id="work-section" className='py-4 py-sm-5'>
                <div className="container">
                    <div className='row'>
                        <div className='col-sm-12 col-md-12 col-lg-6'>
                            <h1 className='head-title'>How does it work?</h1>

                            <div className="work-wrapper mt-5">
                                <h3><strong>1. Buy token on exchange</strong></h3>
                                <p>USDT coin in wallet. Connect wallet and buy token recieve automatic</p>
                                <h3><strong>2. Keep coins in our wallet</strong></h3>
                                <p>Go to swap platform. First connect &amp; confirm wallet, select day token lock, transfer MMS Coin to the system and comfirm a stake.</p>
                                <h3><strong>3. Enjoy regular profits</strong></h3>
                                <p>You rewards are generated daily until the date lock stake Now it’s time to enjoy your life and finally let your money work for you every day.</p>

                            </div>
                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-6 d-flex justify-content-end'>
                            <img src={work} className="lazy img-fluid" alt="pattern" style={{"height" : "400px" , "width" : "400px"}} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="faq-section py-4 py-sm-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="fs-37">
                                Frequently Asked Questions
                            </div>
                            <div className="faq mb-5">
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                1. What fees do you charge for Saving?
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body body-text pe-4">
                                                Saving emergency withdraw fee 20% (Capital + Saving income) And Claim withdraw fee 2% automatic
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                2. What is MMSC Saving?
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body body-text pe-4">
                                                MMSC Saving is the international community of the global decentralized ecosystem and the first ever MMSC smart contract marketing network. This is a self-executing software algorithm that performs the function of distributing partner rewards between community members, subject to certain conditions (Network marketing plan). The contract code is publicly available. Information about transactions can always be viewed at the link bscscan.com
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="heading21">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapse21" aria-expanded="false" aria-controls="collapse21">
                                                3. what is condition for minimum reward claim?
                                            </button>
                                        </h2>
                                        <div id="collapse21" className="accordion-collapse collapse" aria-labelledby="heading21"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body body-text pe-4">
                                                Minimum reward claim is 5 MMSC and you can withdraw after a 24 hours of saving.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseThree" aria-expanded="false"
                                                aria-controls="collapseThree">
                                                4. Who manages the platform?
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body body-text pe-4">
                                                The MMSC Saving platform consists of self-executing trades, which do not permit anyone to interfere with the course of the transactions.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingFour">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseFour" aria-expanded="false"
                                                aria-controls="collapseFour">
                                                5. Who Created MMSC Saving?
                                            </button>
                                        </h2>
                                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body body-text pe-4">
                                                The MMSC Saving concept belongs to a group of crypto enthusiasts, who are members of the community and don’t have any special privileges. Today, MMSC Saving is an peer-to-peer community of platform members, to whom the platform itself belongs.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingFive">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseFive" aria-expanded="false"
                                                aria-controls="collapseFive">
                                                6. What is a smart contract? What are its advabtages?
                                            </button>
                                        </h2>
                                        <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body body-text pe-4">
                                                A smart contract is an algorithm within a cryptocurrency’s blockchain. In our case, MMSC is our first choice among those on which it is possible to create smart contracts. The main purpose of such contracts is the automation of the relationship, the opportunity to make a commitment self-executing.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingsix">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapsesix" aria-expanded="false"
                                                aria-controls="collapsesix">
                                                7. What is Decentralization?
                                            </button>
                                        </h2>
                                        <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="headingsix"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body body-text pe-4">
                                                The process works based on distributing authority from a centralized administration to the participants involved in the process. Unlike a centralized system, all decisions are made by consensus.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingseven">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseseven" aria-expanded="false"
                                                aria-controls="collapseseven">
                                                8. Which wallet should I use?
                                            </button>
                                        </h2>
                                        <div id="collapseseven" className="accordion-collapse collapse" aria-labelledby="headingseven"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body body-text pe-4">
                                                MMSC Saving works with all crypto wallets. We recommend the
                                                following: - for mobile devices (smartphone, tablet) the app,<br />
                                                Trust Wallet <a href="https://trustwallet.com/" rel="noreferrer" target="_blank" >https://trustwallet.com/</a>, <br />
                                                Coinbase Wallet (can be used in USA) <a href="https://www.coinbase.com/" rel="noreferrer" target="_blank" >https://www.coinbase.com/</a>, <br />
                                                for computers and laptops browser extension METAMASK <a href=" https://metamask.io/" rel="noreferrer" target="_blank" > https://metamask.io/</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingeight">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseeight" aria-expanded="false"
                                                aria-controls="collapseeight">
                                                9. What do i need to do to create a trust wallet?
                                            </button>
                                        </h2>
                                        <div id="collapseeight" className="accordion-collapse collapse" aria-labelledby="headingeight"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body body-text pe-4">
                                                To register you will need only a wallet TRUST WALLET - a Google
                                                Chrome extension (PC) and/or some other applications for mobile
                                                devices. Tested and safe applications: -
                                                wallet TRUST WALLET <a href="https://trustwallet.com/" rel="noreferrer" target="_blank" >https://trustwallet.com/</a><br />
                                                <a href=" https://metamask.io/" rel="noreferrer" target="_blank" > https://metamask.io/</a> When registering don't forget to securely and
                                                safely store the password for your wallet.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingnine">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapsenine" aria-expanded="false"
                                                aria-controls="collapsenine">
                                                10. How to buy/sell MMSC if I’ve never dealt with cryptocurrencies before?
                                            </button>
                                        </h2>
                                        <div id="collapsenine" className="accordion-collapse collapse" aria-labelledby="headingnine"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body body-text pe-4">
                                                There are lots of ways to buy/sell cryptocurrencies in exchange for fiat money (regular currencies). They have all been designed for regular users and have user-friendly interfaces. Your first transaction exchanging fiat money for digital currency will take you no more than five minutes. We recommend using the verified currency exchange aggregator.BUY/SELL MMSC link
                                                <br />Link of CEX exchange.<br />
                                                <a href="https://vindax.com/exchange-advanced.html?symbol=MMSC_USDT" rel="noreferrer" target="_blank" > https://vindax.com/exchange-advanced.html?symbol=MMSC_USDT</a> <br />
                                                Link of CEX exchange. <br />
                                                <a href="https://www.finexbox.com/market/pair/MMSC-USDT.html" rel="noreferrer" target="_blank" > https://www.finexbox.com/market/pair/MMSC-USDT.html</a> <br />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingten">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseten" aria-expanded="false"
                                                aria-controls="collapseten">
                                                11. How can i register on the MMSC saving Platform?
                                            </button>
                                        </h2>
                                        <div id="collapseten" className="accordion-collapse collapse" aria-labelledby="headingten"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body body-text pe-4">
                                                To register with MMSC saving and Connect Wellte to the smart contract in order to activate the platforms. The transaction itself constitutes your registration on the platform.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="heading12">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapse12" aria-expanded="false"
                                                aria-controls="collapse12">
                                                12. The MMSC saving community?
                                            </button>
                                        </h2>
                                        <div id="collapse12" className="accordion-collapse collapse" aria-labelledby="heading12"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body body-text pe-4">
                                                No one can close your account, even if they have a strong desire to do so. The account will always be saved in one of the BSCscan network blocks. You will continue to receive income from all levels, except the last active one.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="heading13">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapse13" aria-expanded="false"
                                                aria-controls="collapse13">
                                                13. I have activated the platform, What should i do next?
                                            </button>
                                        </h2>
                                        <div id="collapse13" className="accordion-collapse collapse" aria-labelledby="heading13"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body body-text pe-4">
                                                In order to effectively interact with the MMSC saving platform, you need to: - Have a chat with whoever invited you or with other experienced members. They will help you take your first steps.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="heading18">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapse18" aria-expanded="false"
                                                aria-controls="collapse18">
                                                14.Do i need to withdraw money from MMSC Saving?
                                            </button>
                                        </h2>
                                        <div id="collapse18" className="accordion-collapse collapse" aria-labelledby="heading18"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body body-text pe-4">
                                                When you accumulate 5 MMSC or more and after 24 an hour later, you can claim immediately so the balance of the smart contract is equal to zero. Your income arrives instantly into your personal wallet directly from your partners. Only you have access to your wallet and no one else can manage your money.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="fs-37 text-center">
                                Still have questions?
                            </div>
                            <p className="text-center">
                                If you can not find answer to your question <br />
                                you can mail on Coins2earn@gmail.com.
                            </p> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
