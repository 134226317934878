export const contract = {
    56: {
        // tokenAddress : "0xdb9e13d4f26d4d2a741edbb65e67d27b4d824511",
        stakingAddress: "0x1c6826f8a0d824646f4ea6081e09984534cb03d6",
        multicallAddress: "0x2cc8fe3e04d7b1edaeb42e2e73f78c4cac074116"
    },
    // 97 : {
    //     // tokenAddress : "0xdb9e13d4f26d4d2a741edbb65e67d27b4d824511",
    //     stakingAddress : "0x9e218e2f94913b2c1026f01375f1498ecc9f61cb",
    //     multicallAddress : "0xa54fe4a3dbd1eb21524cd73754666b7e13b4cb18"
    // },
    'default': {
        // tokenAddress : "0xdb9e13d4f26d4d2a741edbb65e67d27b4d824511",
        stakingAddress: "0x1c6826f8a0d824646f4ea6081e09984534cb03d6",
        multicallAddress: "0x2cc8fe3e04d7b1edaeb42e2e73f78c4cac074116"
    }

}

