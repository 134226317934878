import React, { useMemo } from 'react'
import {
    Link,
    useParams,
    useLocation,
} from "react-router-dom";
import Connect from './Connect';
import queryString from "query-string";
import imgBlack from '../images/logo-black.png';
import sLogo from '../images/s-logo.png';
import NetworkMenu from './NetworkMenu';



export default function Header() {
    const router = useRouter();

    return (
        <header id="navbar">
            {/* <!--HEADER SECTION--> */}
            <div className="container-fluid">
                <div className="row d-flex justify-content-center">
                    <div className="col-12">
                        <nav className="navbar navbar-expand-lg navbar-light my-2">
                            <div className="container-fluid">
                                <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <Link className="flex-shrink-0 navbar-brand p-0" to="/">
                                    {/* <!--<img className="w-100 lazy" src="assets/images/logo-black.png" alt="">--> */}
                                    <img className="mob-none lazy" src={imgBlack} alt="logoblack" />
                                    <img className="mob-logo desk-none GFG" style={{ "height": "45px", "width": "auto" }} src={sLogo} alt="logoMobile" />
                                </Link>

                                {/* <Link to="/" className="flex-shrink-0 navbar-brand p-0" >
                                    <img className="w-100" src={imgBlack} alt="logo" />
                                </Link>

                                <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                                    <span className="navbar-toggler-icon"></span>
                                </button> */}
                                <div className="navbar-collapse d-none d-lg-block" id="navbarSupportedContent">
                                    {/* <!--MENU ITEMS--> */}
                                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0 mx-auto align-items-center" >
                                        <li className="nav-item">
                                            <Link to="/" className={`nav-link p-0 text-white ${router.pathname === "/" ? "active" : ""}`} aria-current="page" >Home</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/saving" className={`nav-link p-0 text-white ${router.pathname === "/saving" ? "active" : ""}`} aria-current="page" >Saving</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/my-saving" className={`nav-link p-0 text-white ${router.pathname === "/my-saving" ? "active" : ""}`}>My Saving</Link>
                                        </li>
                                        <li className="nav-item">
                                            <a target="_blank" rel="noreferrer" href="https://mms-soilminerals.com/" className="nav-link p-0 text-white">Website</a>
                                        </li>
                                        <li className="nav-item">
                                            <a class="cs-btn cs-btn3" target="_blank" rel="noreferrer" href="https://vindax.com/exchange-advanced.html?symbol=MMSC_USDT">BUY TOKENS</a>
                                        </li>
                                    </ul>
                                </div>
                                <NetworkMenu />
                                <Connect />
                            </div>
                        </nav>

                        {/* <!--MOBILE MENU--> */}
                        <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample"
                            aria-labelledby="offcanvasExampleLabel">
                            <div className="offcanvas-header">
                                <Link to="/" className="navbar-brand my-3">
                                    <img src={imgBlack} alt="logo-mobile" />
                                </Link>
                                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                                    aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body">
                                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <Link to="/" className={`nav-link p-0 text-white ${router.pathname === "/" ? "active" : ""}`} aria-current="page" >Home</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/saving" className={`nav-link p-0 text-white ${router.pathname === "/saving" ? "active" : ""}`} aria-current="page" >Saving</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/my-saving" className={`nav-link p-0 text-white ${router.pathname === "/my-saving" ? "active" : ""}`}>My Saving</Link>
                                    </li>
                                    <li className="nav-item">
                                        <a target="_blank" rel="noreferrer" href="https://mms-soilminerals.com/" className="nav-link p-0 text-white">Website</a>
                                    </li>
                                    <li className="nav-item">
                                        <a class="cs-btn cs-btn3" target="_blank" rel="noreferrer" href="https://vindax.com/exchange-advanced.html?symbol=MMSC_USDT">BUY TOKENS</a>
                                    </li>
                                </ul>
                                {/* <Connect /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}



// Hook
export function useRouter() {
    const params = useParams();
    const location = useLocation();

    // Return our custom router object
    // Memoize so that a new object is only returned if something changes
    return useMemo(() => {
        return {
            // For convenience add push(), replace(), pathname at top level
            push: location.push,
            replace: location.replace,
            pathname: location.pathname,
            // Merge params and parsed query string into single "query" object
            // so that they can be used interchangeably.
            // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
            query: {
                ...queryString.parse(location.search), // Convert string to object
                ...params,
            },
            // Include match, location, history objects so we have
            // access to extra React Router functionality if needed.
            location,

        };
    }, [params, location]);
}
