import StakeAbi from '../json/tokenstake.json';
import {contract}  from './contract';
import {getWeb3} from './connectors';
import multicallAbi from '../json/multicall.json'

export const getMultiCall = async (calls = [],chainId) => {
  let web3 = getWeb3(chainId);
  let multiCalladdress = contract[chainId] ? contract[chainId].multicallAddress : contract['default'].multicallAddress;
  const mc = new web3.eth.Contract(multicallAbi, multiCalladdress);
  const callRequests = calls.map((call) => {
    const callData = call.encodeABI();
    return {
      target: call._parent._address,
      callData,
    };
  });

  const { returnData } = await mc.methods
    .aggregate(callRequests)
    .call({});

  let finalData = returnData.map((hex, index) => {
    const types = calls[index]._method.outputs.map((o) =>
      o.internalType !== o.type && o.internalType !== undefined ? o : o.type
    );

    let result = web3.eth.abi.decodeParameters(types, hex);

    delete result.__length__;

    result = Object.values(result);

    return result.length === 1 ? result[0] : result;
  });

  return finalData;
}


export const getStakingContract = (chainId) =>{
  let address = contract[chainId] ? contract[chainId].stakingAddress : contract['default'].stakingAddress;
  let web3 = getWeb3(chainId);
  return new web3.eth.Contract(StakeAbi, address);
} 


export const formatPrice = (num) => {
  //return parseFloat(num.toFixed(decimals)).toLocaleString();
  return new Intl.NumberFormat('en-US' , { maximumSignificantDigits: 4 }).format(num);
}